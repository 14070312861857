@charset "utf-8";

@import '../../node_modules/foundation-sites/scss/util/util';
@import '../../node_modules/foundation-sites/scss/util/mixins';

$global-font-size: 62.5%;

$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);

$grid-padding-gutters: (
  small: 10px,
  medium: 60px,
);

$grid-margin-gutters: (
  small: 10px,
  medium: 30px,
);

// $global-font-size: 62.5%;
$global-width: rem-calc(1046);

$breakpoint-classes: (small medium);

$grid-columns: 12;
$xy-block-grid-max: 6;

$grid-margin-gutters: (
  'small': 20px,
  'medium': 30px,
);

@import '../../node_modules/foundation-sites/scss/foundation';

@include foundation-xy-grid-classes(
  $base-grid: true,
  $margin-grid: true,
  $padding-grid: false,
  $block-grid: true,
  $collapse: false,
  $offset: false,
  $vertical-grid: false,
  $frame-grid: false
);

.grid-x.reverse {
  flex-direction: row-reverse;
}
